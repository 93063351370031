import React, { useState, useEffect, useRef } from 'react';
import SEO from '../../components/SEO';
import LoadingSpinner from '../loader';
import { useLocation } from '@reach/router';
import { getVillageDataFromGoogle, postVillageData, getDistrictData, getTehsilData, getPanchayatData, getVillageData } from '../../actions/villageVerifyAction';
import JSONviewer from 'react-json-viewer';
import { Card, CardBody, CardHeader, CardFooter } from '@paljs/ui/Card';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import { Toastr, ToastrRef, ToastrProps } from '@paljs/ui/Toastr';
import VillageListOptions from '../../components/filter/village-list-options'
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import AsyncSelect from 'react-select/async'
import Fuse from 'fuse.js';
import { Radio } from '@paljs/ui/Radio';
import Select from './select-options';
import { getLoggedInUser } from '../../actions/authAction';

const Verify = () => {
  const location = useLocation();
  const url = decodeURI(location.search);
  const params = new URLSearchParams(url);
  const villageNamefromURL = params.get('name');

  const [isLoading, showLoader] = useState(true);
  const [rows, setRows] = useState([]);
  const [village, setVillage] = useState('');
  const [panchayat, setPanchayat] = useState('');
  const [pincode, setPincode] = useState('');
  const [tehsil, setTehsil] = useState('');
  const [district, setDistrict] = useState('');
  const [message, setMessage] = useState('cool toaster');
  const [title, setTitle] = useState('Added Successfully');
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');
  const [mapURL, setMapURL] = useState('')
  const [expCount, setExpCount] = useState('')
  const [user, setUser] = useState("");
  const [area, setArea] = useState("gramin")
  const [toastData, setData] = useState<ToastrProps>({
    position: 'topEnd',
    status: 'Success',
    duration: 2000,
    hasIcon: true,
    destroyByClick: true,
    preventDuplicates: false,
  });

  const toastrRef = useRef<ToastrRef>(null);

  useEffect(() => {
    try {
      const loggedInNode = getLoggedInUser();
      setUser(loggedInNode.name);
      verifiedVillageData(villageNamefromURL)
    } catch (error) {
      // setError('Something went wrong!');
      showLoader(false);
    }
  }, []);
  const verifiedVillageData = async (village) => {
    const response = await getVillageDataFromGoogle(village)
    const data = response.data;

    console.log('getting the data to verify the village', response);
    setRows(data);

    if (data?.verified) {
      const length = data?.verified?.results?.length;
      const sourceData = data?.verified?.results[length - 1];
      setVillage(sourceData?.village_name);
      setDistrict(sourceData?.district);
      setTehsil(sourceData?.panchayat_samiti_nagarapalika);
      setPanchayat(sourceData?.ward_gram_panchayat);
      setPincode(sourceData?.pincode);
      setLatitude(sourceData?.latitude);
      setLongitude(sourceData?.longitude);
      setMapURL(sourceData?.google_map_url);
      setExpCount(sourceData?.expected_count);
      setArea((sourceData?.area_type == "ग्रामीण" || sourceData?.area_type == "gramin" ) ?   area : 'shehri')
    } else {
      const length = data?.suggested_village_data?.results?.length;
      const sourceData = data?.suggested_village_data?.results[length - 1];
      setVillage(sourceData?.village);
      setDistrict(sourceData?.district);
      setTehsil(sourceData?.panchayat_samiti);
      setPanchayat(sourceData?.gram_panchayat);
      // setPincode(sourceData?.pincode);
      // setLatitude(sourceData?.latitude);
      // setLongitude(sourceData?.longitude);
      // setMapURL(sourceData?.google_map_url);
      // setExpCount(sourceData?.expected_count);
      // setArea((sourceData?.area_type) ? sourceData?.area_type : area)
    }

    showLoader(false);

  }

  const dataFromVillageListOptions = (data) => {
    console.log("data from village list options", data);
    setVillage(data.village);
    setTehsil(data.tehsil);
    setDistrict(data.district);
    setPanchayat(data.panchayat);
  }

  const onSubmit = () => {
    const data = {
      app_village_name: villageNamefromURL ?? "",
      village_verified_name: village ?? "",
      pincode: pincode ?? "",
      verifier_name: user,
      district: district ?? "",
      panchayat_samiti: tehsil ?? "",
      gram_panchayat: panchayat ?? "",
      longitude: longitude ?? "",
      latitude: latitude ?? "",
      google_map_url: mapURL ?? "",
      expected_count: expCount ?? "",
      area_type: area ?? ""
    };
    try {
      postVillageData(data, (response: any) => {
        console.log('results after verifying village', response);
        toastrRef.current?.add(message, title, { ...toastData });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const areaType = (value) => {
    console.log("area type ============", value)
    setArea(value);
  }

  const Data = (
    <>
      <Row>
        <Toastr ref={toastrRef} />
        <Col>
          <Card status="Info">
            <CardHeader>{villageNamefromURL}</CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <VillageListOptions onChange={dataFromVillageListOptions} defaultValues={{ village, district, tehsil, panchayat }} />
                </Col>
                <Col breakPoint={{ xs: 12, sm: 4, md: 5, lg: 6 }}>
                  <>Pincode : </>
                  <InputGroup >
                    <input
                      placeholder="Enter Pincode"
                      value={pincode}
                      onChange={(event) => setPincode(event.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col breakPoint={{ xs: 12, sm: 4, md: 5, lg: 6 }}>
                  <>Expected Count : </>
                  <InputGroup >
                    <input
                      placeholder="Enter Exp. Count"
                      value={expCount}
                      onChange={(event) => setExpCount(event.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col breakPoint={{ xs: 12, sm: 4, md: 5, lg: 6 }}>
                  <>Latitude : </>
                  <InputGroup>
                    <input
                      placeholder="Enter Latitude"
                      value={latitude}
                      onChange={(event) => setLatitude(event.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col breakPoint={{ xs: 12, sm: 4, md: 5, lg: 6 }}>
                  <>Longitude : </>
                  <InputGroup>
                    <input
                      placeholder="Enter Longitude"
                      value={longitude}
                      onChange={(event) => setLongitude(event.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col>
                  <>Google Map URL : </>
                  <InputGroup fullWidth size="Small" status="Info">
                    <input
                      placeholder="Enter Map URL"
                      value={mapURL}
                      onChange={(event) => setMapURL(event.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col style={{ display: 'flex' }}>
                  <Radio
                    name="radio"
                    onChange={(event) => areaType(event)}
                    options={[
                      {
                        value: 'shehri',
                        label: 'City',
                        status: 'Info',
                        checked: area == "shehri" ? true : false,
                      },
                      {
                        value: 'gramin',
                        label: 'Village',
                        status: 'Info',
                        checked: area == "gramin" ? true : false,
                      },
                    ]}
                  />
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Button status="Info" type="button" shape="SemiRound" fullWidth onClick={onSubmit}>
                Verify
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      {
        Object.keys(rows).map((item) => {
          return (
            <>
              <h1>{item}</h1>
              <JSONviewer json={rows[item]} />
            </>
          )
        })}
    </>
  );
  return <>{isLoading ? <LoadingSpinner message={`Loading data To Verify ${villageNamefromURL} `} /> : Data}</>;
};
export default Verify;
